import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import Layout from "~/components/Layout";

import moment from "moment";
import FlexibleContent from "~/components/FlexibleContent";
import { Icon, IconType } from "~/components/elements/Icon";
import {
  WpPage_Page_FlexibleContent,
  WpPost,
  WpPostFieldsEnum,
} from "~/_generated/types";
import Switcher from "~/components/elements/Switcher";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import { useSearch } from "~/hooks/useSearch";
import useBlogPosts from "~/hooks/useBlogPosts";
import SearchResults from "~/components/global/SearchResults";
import Image from "~/components/elements/Image";
import SocialSharing from "~/components/elements/SocialSharing";
import Seo from "gatsby-plugin-wpgraphql-seo";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function articlePage({
  data: { wpPage, wpPost, wp, siteSearchIndex },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const [query, setQuery] = useState("");

  const { allWpPost } = useBlogPosts();

  const results = useSearch(siteSearchIndex.index, query);

  const [light, setLight] = useState(false);

  const resourceResults = results
    .filter(({ nodeType }) => nodeType === "Post")
    .map(({ slug }) => allWpPost.nodes.find((post) => post.slug === slug));

  return (
    <Layout
      wp={wp}
      activeSearchQuery={query}
      setQuery={setQuery}
      resourcesHeader
      resourcesHeaderLight={light}
    >
      <Seo post={wpPost} title={wpPost.title} />

      {query.trim().length > 0 ? (
        <SearchResults results={resourceResults} query={query} />
      ) : (
        <div
          className={`pt-[149px] md:pt-[224px] ${
            light ? "text-black bg-purple-4" : "text-white bg-dark-purple"
          } transition-colors duration-300 ease-in-out`}
        >
          <div className="container">
            <div className="flex py-6 md:py-16">
              <Link
                to="/resources"
                className="flex items-center space-x-2.5 transition-opacity hover:opacity-60 duration-300 ease-in-out"
              >
                <Icon type={IconType.ArrowLeft} />
                <span>Back to Resources</span>
              </Link>
              <div className="ml-auto">
                <Switcher
                  active={light}
                  setActive={setLight}
                  showIcons={true}
                  light={light}
                />
              </div>
            </div>
            {wpPost.article.type === "podcast" ||
            wpPost.article.type === "whitepaper" ? (
              <WhitepapersPodcastsHero
                title={wpPost.title}
                article={wpPost.article}
              />
            ) : (
              <DefaultHero title={wpPost.title} />
            )}
            <div className="">
              <div className="flex items-center">
                <div className="flex items-center space-x-8">
                  <div className="text-[16px] md:text-[26px] capitalize">
                    {wpPost.article.type}
                  </div>
                  <div className="flex items-center space-x-2.5">
                    {wpPost.article.readwatchlistenTime && (
                      <>
                        <div className="w-4 h-4 text-current">
                          <Icon type={"clock"} />
                        </div>
                        <span>{wpPost.article.readwatchlistenTime}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="ml-auto text-[16px] md:text-[26px]">
                  <time className="inline-block">
                    {moment(wpPost.date).format("DD-MM-YY")}
                  </time>
                </div>
              </div>
              <div className="pt-8">
                <div className="border-b border-b-purple"></div>
              </div>
            </div>
            <Content wpPost={wpPost} />
          </div>
        </div>
      )}
    </Layout>
  );
}

export const Content = ({ wpPost }) => (
  <div className="mt-8 md:mt-[150px]">
    {wpPost.article.type === "video" && (
      <div className="w-full mb-12 md:mb-24">
        <Video article={wpPost.article} />
      </div>
    )}
    <div className="flex flex-col lg:flex-row ">
      <div className="w-full lg:w-auto ">
        <div className="space-y-10">
          <div className="text-[32px] leading-[1.1]">
            {wpPost.article.summary}
          </div>
          <div className="flex flex-col space-y-3">
            <div className="text-purple text-[14px] capitalize">
              {wpPost.article.type}
            </div>
            <div className="max-w-[170px] leading-[1]">{wpPost.title}</div>
            <div>
              <time className="">{moment(wpPost.date).format("DD-MM-YY")}</time>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <SocialSharing />
        </div>
      </div>
      <div className="w-full lg:w-[688px] flex-auto flex-shrink-0 lg:ml-20 mt-12 lg:mt-0 article">
        {wpPost.article.type === "article" && (
          <div className="mb-16">
            <Image image={wpPost.article.image} />
          </div>
        )}
        {wpPost?.content && (
          <div
            className="pb-16 prose article-content p-large"
            dangerouslySetInnerHTML={{ __html: wpPost?.content }}
          />
        )}

        <FlexibleContent
          content={wpPost?.article?.flexibleContent}
          prefix="Post_Article_FlexibleContent_"
        />
      </div>
    </div>
  </div>
);

export const DefaultHero = ({ title }) => {
  return (
    <div className="max-w-[870px] pt-12 pb-12 md:pb-48">
      <h1 className="text-h1-large !font-body">{title}</h1>
    </div>
  );
};

export const WhitepapersPodcastsHero = ({ title, article }) => {
  return (
    <div className="grid grid-cols-12 mb-24">
      <div className="col-span-12 lg:col-span-8">
        <h1 className="text-h1-large !font-body">{title}</h1>
      </div>
      <div className="col-span-12 mt-8 lg:mt-0 lg:col-span-3 lg:col-start-10">
        <Image image={article.image} />
      </div>
    </div>
  );
};

export const Video = ({ article }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (typeof window === "undefined" || !article?.video?.video) return null;

  return (
    <>
      <div
        className="relative cursor-pointer group"
        onClick={() => setIsOpen(true)}
      >
        <Image image={article.video.image} />
        <div className="absolute inset-0 z-10 flex flex-col justify-center bg-dark-purple bg-opacity-70">
          <div className="flex items-center ml-8 md:ml-32">
            <div className="relative z-10 flex items-center justify-center bg-white rounded-full text-dark-purple w-28 h-28 ">
              <div className="absolute inset-0 transition-transform duration-300 ease-in-out bg-white rounded-full w-28 h-28 group-hover:scale-110" />
              <div className="absolute ml-1 transform -translate-x-1/2 -translate-y-1/2 w-7 fw-icon top-1/2 left-1/2">
                <Icon type={IconType.Play} />
              </div>
            </div>
            <div className="text-[21px] ml-3">Play video</div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="custom"
        autoplay={1}
        isOpen={isOpen}
        url={article?.video?.video?.localFile?.publicURL}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
    siteSearchIndex {
      index
    }
  }
`;
