import React, { useState, useEffect } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Icon, IconType } from './Icon';

export default function StoryPreview({ className }) {
  const [pageUrl, setPageUrl] = useState('');
  useEffect(() => {
    setPageUrl(window?.location?.href);
  }, []);

  return (
    <div className={`flex md:flex-col items-start space-x-2.5 md:space-x-0 md:space-y-2.5`}>
      <FacebookShareButton url={pageUrl}>
        <div className="w-[30px] h-[30px] border border-current rounded-lg flex items-center justify-center hover:opacity-50 transition-opacity duration-300 ease-in-out group">
          <div className="w-4 h-4 fw-icon text-current">
            <Icon type={IconType.Facebook} className="w-4 h-auto text-current" />
          </div>
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={pageUrl}>
        <div className="w-[30px] h-[30px] border border-current rounded-lg flex items-center justify-center hover:opacity-50 transition-opacity duration-300 ease-in-out group">
          <div className="w-4 h-4 fw-icon text-current">
            <Icon type={IconType.Twitter} className="w-4 h-auto text-current"  />
          </div>
        </div>
      </TwitterShareButton>
      <LinkedinShareButton url={pageUrl}>
        <div className="w-[30px] h-[30px] border border-current rounded-lg flex items-center justify-center hover:opacity-50 transition-opacity duration-300 ease-in-out group">
          <div className="w-4 h-4 fw-icon text-current">
            <Icon type={IconType.LinkedIn} />
          </div>
        </div>
      </LinkedinShareButton>
    </div>
  );
}
